<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">
              {{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span> {{ item }} </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="branch_code">Branch Code</vs-th>
        <vs-th sort-key="branch_name">Branch Name</vs-th>
        <vs-th sort-key="division_code">Division Code</vs-th>
        <vs-th sort-key="division_description">Division Description</vs-th>
        <vs-th sort-key="material">Material</vs-th>
        <vs-th sort-key="material_description">Material Description</vs-th>
        <vs-th sort-key="from_date">From Date</vs-th>
        <vs-th sort-key="to_date">To Date</vs-th>
        <vs-th sort-key="uom">UOM</vs-th>
        <vs-th sort-key="opening_stock">Opening Stock</vs-th>
        <vs-th sort-key="total_receipt_quantities"
          >Total Receipt Quantities</vs-th
        >
        <vs-th sort-key="total_issue_quantities">Total Issue Quantities</vs-th>
        <vs-th sort-key="closing_stock">Closing Stock</vs-th>
        <vs-th sort-key="opening_value">Opening Value</vs-th>
        <vs-th sort-key="total_receipt_values">Total Receipt Values</vs-th>
        <vs-th sort-key="total_issue_value">Total Issue Value</vs-th>
        <vs-th sort-key="closing_value">Closing Value</vs-th>
        <vs-th sort-key="adjustment_value">Adjustment Value</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].branch_code">{{
            data[indextr].branch_code
          }}</vs-td
          ><vs-td :data="data[indextr].branch_name">{{
            data[indextr].branch_name
          }}</vs-td
          ><vs-td :data="data[indextr].division_code">{{
            data[indextr].division_code
          }}</vs-td
          ><vs-td :data="data[indextr].division_description">{{
            data[indextr].division_description
          }}</vs-td
          ><vs-td :data="data[indextr].material">{{
            data[indextr].material
          }}</vs-td
          ><vs-td :data="data[indextr].material_description">{{
            data[indextr].material_description
          }}</vs-td
          ><vs-td :data="data[indextr].from_date">{{
            data[indextr].from_date
          }}</vs-td
          ><vs-td :data="data[indextr].to_date">{{
            data[indextr].to_date
          }}</vs-td
          ><vs-td :data="data[indextr].uom">{{ data[indextr].uom }}</vs-td
          ><vs-td :data="data[indextr].opening_stock">{{
            formatPrice(data[indextr].opening_stock)
          }}</vs-td
          ><vs-td :data="data[indextr].total_receipt_quantities">{{
            formatPrice(data[indextr].total_receipt_quantities)
          }}</vs-td
          ><vs-td :data="data[indextr].total_issue_quantities">{{
            formatPrice(data[indextr].total_issue_quantities)
          }}</vs-td
          ><vs-td :data="data[indextr].closing_stock">{{
            formatPrice(data[indextr].closing_stock)
          }}</vs-td
          ><vs-td :data="data[indextr].opening_value">{{
            formatPrice(data[indextr].opening_value)
          }}</vs-td
          ><vs-td :data="data[indextr].total_receipt_values">{{
            formatPrice(data[indextr].total_receipt_values)
          }}</vs-td
          ><vs-td :data="data[indextr].total_issue_value">{{
            formatPrice(data[indextr].total_issue_value)
          }}</vs-td
          ><vs-td :data="data[indextr].closing_value">{{
            formatPrice(data[indextr].closing_value)
          }}</vs-td
          ><vs-td :data="data[indextr].adjustment_value">{{
            formatPrice(data[indextr].adjustment_value)
          }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    startPostingDate: {
      type: Date,
    },
    endPostingDate: {
      type: Date,
    },
    startDocDate: {
      type: Date,
    },
    endDocDate: {
      type: Date,
    },
    startDueDate: {
      type: Date,
    },
    endDueDate: {
      type: Date,
    },
    openKeyDate: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportSTPostingDate.tableData,
      total: (state) => state.reportSTPostingDate.total,
      totalPerPage: (state) => state.reportSTPostingDate.totalPerPage,
      totalPage: (state) => state.reportSTPostingDate.totalPage,
      totalSearch: (state) => state.reportSTPostingDate.totalSearch,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({
      getTableData: 'reportSTPostingDate/getSTPostingDateReport',
      generateSTPostingDateReport: 'reportSTPostingDate/generateSTPostingDateReport',
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: '',
        order: 'branch_code',
        sort: 'desc',
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, 'All'],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == 'All' ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.getTableData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
      }).then((resp) => {
        this.$vs.loading.close();
        this.table.data = resp.records;
      });
    },
    setStartEnd() {
      let valStart =
        this.tableData.length * this.table.page - this.tableData.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.tableData.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (this.totalSearch < this.table.total && this.table.search != '') {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format('DD MMM YYYY');
    },
    handleExport(file) {
      this.$vs.loading();
      this.generateSTPostingDateReport({
        search: this.table.search,
        length: this.table.length,
        page: this.table.page,
        order: this.table.order,
        sort: this.table.sort,
      }).then((resp) => {
        this.$vs.notify({
          color: 'success',
          title: 'Processing',
          text: resp.message,
          position: 'top-right',
          iconPack: 'feather',
          icon: 'icon-x-circle',
        });
      }).catch((err) => {
        this.$vs.notify({
          color: 'danger',
          title: 'Error',
          text: err.message,
          position: 'top-right',
          iconPack: 'feather',
          icon: 'icon-x-circle',
        });
      }).finally(() => {
        this.$vs.loading.close();
      });
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    format(head, value) {
      if (typeof head['format'] === 'function') {
        var f = head['format'];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    // startPostingDate() {
    //   this.getData();
    // },
    // endPostingDate() {
    //   this.getData();
    // },
    // startDocDate() {
    //   this.getData();
    // },
    // endDocDate() {
    //   this.getData();
    // },
    // startDueDate() {
    //   this.getData();
    // },
    // endDueDate() {
    //   this.getData();
    // },
    // openKeyDate() {
    //   this.getData();
    // },
    // territoryID() {
    //   this.getData();
    // },
    draw() {
      this.getData();
    },
    tableData(val) {
      this.table.data = val;
    },
    total(val) {
      this.table.total = val;
    },
    totalPerPage(val) {
      this.table.totalPerPage = val;
    },
    totalPage(val) {
      this.table.totalPage = val;
    },
    totalSearch(val) {
      this.table.totalSearch = val;
    },
    'table.data'() {
      this.setStartEnd();
    },
  },
  // mounted() {
  //   this.getData();
  // },
};
</script>
